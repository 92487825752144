import React, { useState, useEffect } from "react";
import { db, db2, auth } from "../firebase-config";
import {
  doc,
  getDoc,
  addDoc,
  collection,
  onSnapshot,
  query,
  serverTimestamp,
  where,
  orderBy,
  getDocs,
} from "firebase/firestore";
import "../styles/Chat.scss";

export const Jukebox = () => {
  const [jukebox, setJukebox] = useState([]);
  const jukeboxRef = collection(db2, "jukebox");
  const [inputFocus, setInputFocus] = useState(false);
  const [originalJukebox, setOriginalJukebox] = useState([]);
  const [count, setCount] = useState(originalJukebox.length);
  const [modalshow, setModalShow] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [requestedTrack, setRequestedTrack] = useState({});
  const [DJChatId, setDJChatId] = useState("N9TkGYDwV8jvuifIfBQc");
  const [messages, setMessages] = useState([]);
  const [avatars, setAvatars] = useState({});
  const [requestList, setRequestList] = useState([]);

  useEffect(() => {
    const queryMessages = query(
      jukeboxRef /*,
      where("room", "==", room),
      orderBy("createdAt")*/
    );
    const unsuscribe = onSnapshot(queryMessages, (snapshot) => {
      let jukebox = [];
      snapshot.forEach((doc) => {
        jukebox.push({ ...doc.data(), id: doc.id });
      });
      setJukebox(jukebox[0].jukeboxList);
      setOriginalJukebox(jukebox[0].jukeboxList);
    });

    return () => unsuscribe();
  }, []);


  useEffect(() => {
    const chatsMessgesRef = collection(db, "djchats", DJChatId, "messages");
    const queryMessages = query(chatsMessgesRef, orderBy("createdAt"));
    const unsuscribe = onSnapshot(queryMessages, (snapshot) => {
      let messagesx = [];
      let avatars = [];

      snapshot.forEach((doc) => {
        // create an avatar list that can be referred to when the chatlist is rendered
        avatars.push(doc.data().uid);
        messagesx.push(Object.assign({ ...doc.data(), id: doc.id }));
        console.log("messagesx", messagesx);
      });

      new Promise(function (resolve, reject) {
        const avatarObj = {};
        const uniq = [...new Set(avatars)];
        let i = 0;
        // search for these uids and the photoURL that goes with them
        uniq.forEach(async (uid) => {
          i++;
          const docRef = doc(db, "users", uid);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            avatarObj[uid] = {
              displayName: docSnap.data().displayName,
              photoURL: docSnap.data().photoURL,
              email: docSnap.data().email,
            };
          } else {
            // some random image maybe an ai generated icon and displayname
            avatarObj[uid] = {
              displayName: docSnap.data().displayName,
              photoURL: docSnap.data().photoURL,
            };
          }
        });
        if (i >= uniq.length) resolve(avatarObj);
        else reject();
      })
        .then(function (avatarObj) {
          return new Promise((resolve, reject) => {
            setTimeout(() => resolve(setAvatars(avatarObj)), 300);
          });
        })
        .then(function (result) {
          setMessages(messagesx);
          // setTimeout(() => {
          //   updateWatchList(messagesx.length - 1);
          //   // update
          // }, 2000);
        });
    });
    return () => unsuscribe();
  }, [DJChatId]);

  const checkUserPickInList = (item) => {
    console.log("checkUserPickInList", item.uid);
    // check if user has already picked a song in the last 20 minutes
    const requestFeedbackRef = collection(db, "djchats");
    const queryMessages = query(
      requestFeedbackRef,
      where("uid", "==", item.uid),
      orderBy("createdAt")
    );
    onSnapshot(queryMessages, (snapshot) => {
      let requestFeedback = [];
      snapshot.forEach((doc) => {
        const thisItemtimeStamp = doc.data().createdAt;
        console.log("thisItemtimeStamp", thisItemtimeStamp.seconds);
        const timestampInSeconds = (Date.now() / 1000) | 0;
        console.log("timestampInSeconds", timestampInSeconds);
        console.log("how to convert these and substract 20 minutes");

        if (timestampInSeconds - thisItemtimeStamp.seconds < 1200) {
          console.log(
            "you have already requested a song in the last 20 minutes"
          );
        } else {
          requestFeedback.push({ ...doc.data(), id: doc.id });
        }
      });
      console.log("requestFeedback", requestFeedback);
    });
  };

  const arraySearch = (array, keyword) => {
    //console.log('array', array);
    const searchTerm = keyword.toLowerCase();
    return array.filter((value) => {
      // console.log('value', value);
      if (!value.track) value.track = value.artist;
      if (!value.artist) value.artist = value.track;
      if (!value.artist && !value.track) return null;
      return (
        value.track.toLowerCase().match(new RegExp(searchTerm, "g")) ||
        value.artist.toLowerCase().match(new RegExp(searchTerm, "g"))
      );
    });
  };

  const handleSubmit = async (event) => {
    let value = event.target.value;
    if (value.length > 2) {
      let search = await arraySearch(jukebox, value);
      setJukebox(search);
      setCount(search.length);
    } else {
      setJukebox(originalJukebox);
      setCount(originalJukebox.length);
    }
    // console.log("sEaRCh UsErS", jukebox);
  };

  const pickTrack = async (event, item, i) => {
    console.log("send this data to dj app", item, i);
    if (isOpen) {
      setIsOpen(!isOpen);
    } else {
      setIsOpen(!isOpen);

      /*

      send this data plus user name to a firestore doc, timestamped.
      the dj app will watch this document and update when anyone sends 
      a request it will look like a chat ont he DJ app. he can click 
      the message bubble and reply to the user who sent it, telling them 
      that he'll play it and maybe when. the dj app queue/chat screen 
      will update inserting the dj reply underneath the reqyuest bubble. 
      The user/audience member will see it as an enhanced chat with dj. 
      Cannot reply back to the dj for 20 minutes after request 
      (to stop nutters abusing) use their user profile to timestamp when
      can next request a song

      before sending the picked track tot he djchats list, check if the user 
      has already requested a song in the list less than 20 minutes ago
    */
      // const user = auth.currentUser;
      // const uid = user.uid;
      // const pickedItem = {
      //   request: item,
      //   createdAt: serverTimestamp(),
      //   user: auth.currentUser.displayName || auth.currentUser.email,
      //   uid: auth.currentUser.uid,
      // }
      //checkUserPickInList(pickedItem);

      // read the whole request list to find if this is already in it
      // request.track request.artist

      // requestList.forEach((request) => {
      //   console.log("request", request.request.track, request.request.artist);
      //   if (
      //     request.request.track === item.track &&
      //     request.request.artist === item.artist
      //   ) {
      //     console.log("this song has already been requested");
      //   }

      //   console.log(
      //     "request.createdAt.seconds in list",
      //     Math.floor(Date.now() / 1000) - request.createdAt.seconds
      //   );
      //   if (Math.floor(Date.now() / 1000) - request.createdAt.seconds < 1201) {
      //     console.log(
      //       "this user has already requested a song in the last 20 minutes"
      //     );
      //   }
      // });

      const requestSongRef4 = collection(db, "djchats");
      try {
        const newprod = await addDoc(requestSongRef4, {
          request: item,
          createdAt: serverTimestamp(),
          user: auth.currentUser.displayName || auth.currentUser.email,
          uid: auth.currentUser.uid,
        });
        console.log("djchat document written with ID: ", newprod.id);
        setDJChatId(newprod.id);
        const requestSongRef5 = collection(
          db,
          "djchats",
          newprod.id,
          "requesters"
        );
        try {
          const newrequester = await addDoc(requestSongRef5, {
            createdAt: serverTimestamp(),
            user: auth.currentUser.displayName || auth.currentUser.email,
            uid: auth.currentUser.uid,
          });
          console.log("newrequester id: ", newrequester.id);
        } catch (error) {
          console.error("Error adding document: ", error);
        }

        const requestSongRef6 = collection(
          db,
          "users",
          auth.currentUser.uid,
          "requests"
        );
        try {
          const userrequest = await addDoc(requestSongRef6, {
            createdAt: serverTimestamp(),
            djchatId: newprod.id,
          });
          console.log("userrequest id: ", userrequest.id);
        } catch (error) {
          console.error("Error adding document: ", error);
        }
      } catch (error) {
        console.error("Error adding document: ", error);
      }

      /*
    this user can now specifically wait for updates to the above record from the dj app
    as it has their uid as its id in djchats collection
    */
    }
  };

  return (
    <>
      <div
        className="flex flex-col flex-auto items-center h-full jukebox-panel eebbzz"
        style={{
          height: "calc(100dvh - 226px)",
          width: "-webkit-fill-available",
        }}
      >
        <div className="flex flex-row text-white items-center pb-1 font-black">
          <div>
            DJ OBAH<p className="text-gray-100 text-base">Jukebox</p>
          </div>
          <div className="dj-wc-logo"></div>
        </div>
        <div className="flex flex-col flex-auto flex-shrink-0 rounded-2xl bg-gray-100 h-full p-4 panel-fade">
          <div
            className={`flex flex-row items-center h-16 rounded-xl bg-white w-full px-4 h-24 text-enter-fit ${
              inputFocus === true ? "text-enter-full" : ""
            }`}
          >
            <div className="flex-grow ml-2 h-20">
              <div className="relative w-full">
                <input
                  type="text"
                  placeholder="search jukebox"
                  onFocus={() => setInputFocus(true)}
                  onChange={handleSubmit}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") handleSubmit(e);
                  }}
                  className="flex w-full text-gray-800 dark:text-gray-800 border rounded-xl focus:outline-none focus:border-indigo-300 pl-4 h-20"
                />
              </div>
            </div>
            <div className="ml-2">
              <button
                onClick={(event) => handleSubmit(event)}
                className="flex items-center justify-center bg-indigo-500 hover:bg-indigo-600 rounded-xl text-white px-4 py-1 flex-shrink-0 w-4 h-8"
              >
                <span className="ml-0">
                  <svg
                    className="w-4 h-4 transform rotate-45 -mt-px"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
                    ></path>
                  </svg>
                </span>
              </button>
            </div>
          </div>

          <div
            className="flex flex-col h-full overflow-x-auto mb-4"
            style={{ height: "calc(100dvh - 182px)" }}
          >
            <div className="flex flex-col h-full">
              <div className="">
                {isOpen && (
                  <div className="bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 muuz-confirm-panel">
                    <div
                      className="py-2 text-sm text-gray-700 dark:text-gray-200"
                      aria-labelledby="dropdownDefaultButton"
                    >
                      <div className="text-center">
                        <div
                          onClick={() => {
                            setIsOpen(!isOpen);
                          }}
                          class="muuzbox-section-button"
                        >
                          <div
                            id="notifyMuuzbox-close"
                            class="section-close muuzbox-close rounded"
                          ></div>
                        </div>

                        <button
                          onClick={() => {
                            setIsOpen(!isOpen);
                          }}
                          className="block w-full text-center px-4 py-2 hover:bg-gray-600 dark:hover:bg-gray-600 dark:hover:text-white muuzmenuitem"
                        >
                          <div>
                            <strong>{requestedTrack.artist}</strong>
                          </div>
                          <div>{requestedTrack.track}</div>
                        </button>
                        <div className="block w-full text-xl text-center text-red-800">
                          DJ OBAH has been informed of your request
                        </div>
                      </div>

                      {messages && (
                        <>
                          {messages.map((message, i) => (
                            <div
                              className="flex flex-row items-center hover:bg-gray-100 rounded-xl p-2"
                              key={i}
                            >
                              <div className="flex items-center justify-center h-12 w-12 rounded-full border overflow-hidden bg-indigo-500 flex-shrink-0">
                                <img
                                  src={avatars[message.uid]?.photoURL}
                                  alt={avatars[message.uid]?.displayName}
                                />
                              </div>
                              <div className="ml-2 text-lg font-semibold">
                                {message.text}
                              </div>
                            </div>
                          ))}
                        </>
                      )}

                      {messages.length === 0 && (
                        <div class="muuzbox-loader-logo-sm fadeIn">
                          <img src="img/muuzbox-logo.png" alt="Muuzbox logo" />
                          <div class="muuzbox-loader-blob"></div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <ul className=" divide-y divide-gray-200">
                  {jukebox &&
                    jukebox.map((item, i) => (
                      <li
                        className="flex items-center py-4 px-3 jukebox-divider"
                        key={i}
                        // onClick={() => setIsOpen(!isOpen)}
                        onClick={(event) => {
                          setRequestedTrack(item);
                          pickTrack(event, item, i);
                        }}
                      >
                        <span className="text-gray-100 text-lg font-black font-medium">
                          {i + 1}.
                        </span>
                        <img
                          className=" rounded-full object-cover mr-1"
                          src={item.coverart}
                          style={{ width: "48px" }}
                          alt={item.track}
                        />
                        <div className="flex-1" style={{ width: "50%" }}>
                          <h3 className="text-lg font-medium text-white overflow-hidden">
                            {item.artist}
                          </h3>
                          <p className="text-gray-100 text-base">
                            {item.track}{" "}
                          </p>
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
