import React, { useState, useEffect } from "react";
import { db, auth } from "../firebase-config";
import {
  collection,
  onSnapshot,
  query,
  doc,
  getDoc,
  setDoc,
  serverTimestamp,
  updateDoc,
  where,
  orderBy,

  // orderBy, // eventualy order by name
} from "firebase/firestore";
import { Avatar } from "./Avatar";
import VerticalCarousel from "./VerticalCarousel";
import { useAuth } from "../context/AuthUserContext";

import "../styles/Chat.scss";

export const ChatList = ({ pickedChat, thinLayout }) => {
  const { currentUser, loading } = useAuth();
  const [chatlist, setChatlist] = useState([]);
  const [avatars, setAvatars] = useState({});
  const [requestList, setRequestList] = useState([]);

  useEffect(() => {
    console.log("auth", auth);
    console.log("currentUser", currentUser);
    console.log("loading", loading);
    // change all of this to auth context so its not an issue again

    setTimeout(() => {
      let gotCurrentChatId = "";
      new Promise((resolve, reject) => {
        gotCurrentChatId = getCurrentChat();
        if (gotCurrentChatId !== "") {
          resolve(gotCurrentChatId);
        } else reject();
      }).then((results) => {
        getDJChatList(results);
      });
    }, 2000);
  }, []);

  const openChat = async (e, chatId, type) => {
    e.preventDefault();
    let trackDetailsIndex = requestList.findIndex(
      (item) => item.chatId === chatId
    );
    pickedChat(chatId, type, requestList[trackDetailsIndex]);

    const docRef = doc(db, "users", auth.currentUser.uid);
    const docSnap = await getDoc(docRef);
    const currentChat = { currentChat: chatId };
    if (docSnap.exists()) {
      try {
        await updateDoc(docRef, { ...currentChat });
      } catch (err) {
        console.log(err);
      }
    }
    setTimeout(() => {
      getChatList(chatId, requestList);
    }, 2000);
  };

  const getCurrentChat = async () => {
    const docRef = doc(db, "users", auth.currentUser.uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data().currentChat;
    }
  };

  const getDJChatList = async (results) => {
    const requestFeedbackRef = collection(db, "djchats");
    const queryMessages = query(
      requestFeedbackRef,
      where("uid", "==", auth.currentUser.uid),
      orderBy("createdAt")
    );

    const unsuscribe = onSnapshot(queryMessages, (snapshot) => {
      let requestFeedback = [];
      snapshot.forEach((doc) => {
        if (!(doc.data().status && doc.data().status === "closed")) {
          requestFeedback.push({
            // ...doc.data(),
            chatId: doc.id,
            chatLength: 0,
            createdAt: doc.data().createdAt,
            id: doc.id,
            type: "djchat",
            unreadAmount: doc.data().unreadAmount,
            users: [{ id: "put-the-dj-user-id-here" }],
            coverart: doc.data().request.coverart,
            track: doc.data().request.track,
          });
        }
      });

      console.log("requestFeedback", requestFeedback);
      // send requestFeedback to ChatList component and add it to the list

      setRequestList(requestFeedback);
      getChatList(results, requestFeedback);
    });
    return () => unsuscribe();
  };

  const getChatList = async (currentChatId, requestFeedback) => {
    if (auth.currentUser) {
      const chatRef = collection(db, "users", auth.currentUser.uid, "chats");
      const queryChats = query(
        chatRef /*,
      where("room", "==", room),
      orderBy("createdAt")*/
      );
      const unsuscribe = onSnapshot(queryChats, (snapshot) => {
        let chats = [];
        let avatars = [];
        snapshot.forEach((doc) => {
          const chatUsers = doc.data().users;
          avatars.push(chatUsers[1].id);
          chats.push({ ...doc.data(), id: doc.id });
          // get the length of each chat
        });
        new Promise((resolve, reject) => {
          const avatarObj = {};
          let i = 0;
          // search for these uids and the photoURL that goes with them
          avatars.forEach(async (uid) => {
            i++;
            const docRef = doc(db, "users", uid);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
              avatarObj[uid] = {
                displayName: docSnap.data().displayName,
                photoURL: docSnap.data().photoURL,
                email: docSnap.data().email,
              };
            } else {
              // some random image maybe an ai generated icon and displayname
              console.log("no docSnap.exists()");
            }
          });
          if (i >= avatars.length) resolve(avatarObj);
          else reject();
        })
          .then(function (avatarObj) {
            return new Promise((resolve, reject) => {
              setTimeout(() => resolve(setAvatars(avatarObj)), 500);
            });
          })
          .then((res) => {
            return new Promise((resolve, reject) => {
              setTimeout(() => {
                const newChatlist = [];
                chats.forEach(async (item, i) => {
                  const lclRef = doc(
                    db,
                    "users",
                    auth.currentUser.uid,
                    "updatewatchList",
                    item.chatId
                  );
                  const lclDoc = await getDoc(lclRef);
                  if (lclDoc.exists()) {
                    let unreadAmount;
                    if (item.chatLength === undefined) {
                      unreadAmount = 0;
                    } else {
                      let x = item.chatLength - lclDoc.data().chatLength;
                      if (x < 0) unreadAmount = 0;
                      else
                        unreadAmount =
                          item.chatLength - lclDoc.data().chatLength;
                    }

                    // if its the current chat open
                    if (lclDoc.data().chatId === currentChatId) {
                      item.unreadAmount = 0;
                    } else {
                      item.unreadAmount = unreadAmount;
                    }

                    newChatlist.push(item);
                  } else {
                    // lclDoc doesn't exists yet so let insert on
                    const updatewatchListRef2 = doc(
                      db,
                      "users",
                      auth.currentUser.uid,
                      "updatewatchList",
                      item.chatId
                    );

                    try {
                      const thisChat2 = {
                        chatId: item.chatId,
                        updatedAt: serverTimestamp(),
                        chatLength: 0,
                      };
                      const updatewatchListDocSnap = await getDoc(
                        updatewatchListRef2
                      );
                      if (updatewatchListDocSnap.exists()) {
                        //   console.log("setDoc updatewatchList", thisChat2);
                        await setDoc(
                          doc(
                            db,
                            "users",
                            auth.currentUser.uid,
                            "updatewatchList",
                            item.chatId
                          ),
                          thisChat2
                        );
                      } else {
                        await setDoc(
                          doc(
                            db,
                            "users",
                            auth.currentUser.uid,
                            "updatewatchList",
                            item.chatId
                          ),
                          thisChat2
                        );
                      }
                    } catch (err) {
                      console.log(err);
                    }
                  }
                });

                //   console.log("newChatlist", newChatlist);

                if (newChatlist.length >= 0) resolve(newChatlist);
                else reject();
              }, 500);
            });
          })
          .then((newChatlist) => {
            console.log("newChatlist", newChatlist);
            console.log("requestFeedback", requestFeedback);

            return new Promise((resolve, reject) => {
              setTimeout(() => {
                let combinedList;
                if (requestFeedback)
                  combinedList = newChatlist.concat(requestFeedback);
                else combinedList = newChatlist;
                console.log("combinedList", combinedList);
                resolve(setChatlist(combinedList));
              }, 500);
            });
          });
      });
      return () => unsuscribe();
    }
  };

  // checks if there are new unread messages for each chat in the list here
  // onsnap reacts to the chat having the update field modified then reads
  // the messages and compares length to previous length

  return (
    <>
      {currentUser && thinLayout ? (
        <>
          <VerticalCarousel
            avatars={avatars}
            data={chatlist}
            openChat={openChat}
          />
        </>
      ) : (
        <>
          {chatlist.map((chat, i) => (
            <button
              className="flex flex-row items-center hover:bg-gray-100 rounded-xl p-2"
              key={chat.id}
              onClick={(e) => openChat(e, chat.chatId, chat.type)}
            >
              <div className="flex items-center justify-center h-8 w-8 rounded-full border overflow-hidden bg-indigo-500 flex-shrink-0">
                {chat.type === "private" ? (
                  <Avatar avObj={avatars} uid={chat.users[1].id} />
                ) : (
                  <div>
                    <img
                      src={chat.coverart}
                      alt={chat.track}
                      title={chat.track}
                    />
                  </div>
                )}
              </div>
              <div className="ml-2 text-sm font-semibold">
                {chat.users[0].id === auth.currentUser.uid ? (
                  <div
                    style={{ width: "100px", overflowX: "hidden" }}
                    className="text-left text-ellipsis"
                  >
                    {chat.users[1].displayName === "" ? (
                      <>{chat.users[1].email}</>
                    ) : (
                      <>{chat.users[1].displayName}</>
                    )}
                  </div>
                ) : (
                  <div
                    style={{ width: "100px", overflowX: "hidden" }}
                    className="text-left text-ellipsis"
                  >
                    {chat.track}
                  </div>
                )}
              </div>
              <div className="flex items-center justify-center ml-auto text-xs text-white bg-red-500 h-4 w-4 rounded leading-none">
                {chat.unreadAmount}
              </div>
            </button>
          ))}
        </>
      )}
    </>
  );
};
